import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`23rd November 2019`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The type signature of clients and services has been greatly simplified. Note that this involves various breaking changes unfortunately. See the 'Breaking changes' section for the details. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2239"
          }}>{`#2239`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2254"
          }}>{`#2254`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
public class FooHttpService implements Service<HttpRequest, HttpResponse> { ... }
public class FooRpcService implements Service<RpcRequest, RpcResponse> { ... }
public class FooHttpClient implements Client<HttpRequest, HttpResponse> { ... }
public class FooRpcClient implements Client<RpcRequest, RpcResponse> { ... }

Service<HttpRequest, HttpResponse> foo(Service<HttpRequest, HttpResponse> bar) { ... }

Function<Service<HttpRequest, HttpResponse>,
         ? extends Service<HttpRequest, HttpResponse>> httpServiceDecorator;
Function<Service<RpcRequest, RpcResponse>,
         ? extends Service<RpcRequest, RpcResponse>> rpcServiceDecorator;
Function<Client<HttpRequest, HttpResponse>,
         ? extends Client<HttpRequest, HttpResponse>> httpClientDecorator;
Function<Client<RpcRequest, RpcResponse>,
         ? extends Client<RpcRequest, RpcResponse>> rpcClientDecorator;

// After:
public class FooHttpService implements HttpService { ... }
public class FooRpcService implements RpcService { ... }
public class FooHttpClient implements HttpClient { ... }
public class FooRpcClient implements RpcClient { ... }

HttpService foo(HttpService bar) { ... }

Function<? super HttpService, ? extends HttpService> httpServiceDecorator;
Function<? super RpcService, ? extends RpcService> rpcServiceDecorator;
Function<? super HttpClient, ? extends HttpClient> httpClientDecorator;
Function<? super RpcClient, ? extends RpcClient> rpcClientDecorator;
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify service-level settings for annotated services, just like other service types. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2180"
          }}>{`#2180`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2222"
          }}>{`#2222`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server =
    Server.builder()
          .annotatedService().pathPrefix("/api")
                             .requestTimeoutMillis(5000)
                             .exceptionHandler((ctx, req, cause) -> ...)
                             .build(new Object() { ... }))
          .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`RequestContext`}</inlineCode>{` now has its own `}<inlineCode parentName="p">{`RequestId`}</inlineCode>{`, which is a 64-bit random integer by default. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2001"
          }}>{`#2001`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2174"
          }}>{`#2174`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2203"
          }}>{`#2203`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2224"
          }}>{`#2224`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server =
    Server.builder()
          .service("/", (ctx, req) -> {
              return HttpResponse.of("Request ID: %s", ctx.id().text());
          })
          .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now add a cause to an `}<inlineCode parentName="p">{`HttpStatusException`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2253"
          }}>{`#2253`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpService service = (ctx, req) -> {
    try (FileInputStream in = new FileInputStream(...)) {
        ...
    } catch (FileNotFoundException e) {
        throw HttpStatusException.of(HttpStatus.NOT_FOUND, e);
    }
};
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Armeria server now always has a fallback service which is matched when no routes match a request. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1625"
          }}>{`#1625`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2255"
          }}>{`#2255`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`As a result, a route decorator bound at `}<inlineCode parentName="li">{`prefix:/`}</inlineCode>{` can intercept any requests, even the requests not handled by any services:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// CorsService will intercept any requests,
// even the ones not handled by any services.
Server server =
    Server.builder()
          .service("/foo", fooService)
          .service("/bar", barService)
          .routeDecorator().pathPrefix("/")
                           .build(CorsService.newDecorator(...))
          .build();
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now determine the log level of `}<inlineCode parentName="p">{`LoggingClient`}</inlineCode>{` and `}<inlineCode parentName="p">{`LoggingService`}</inlineCode>{` dynamically. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2250"
          }}>{`#2250`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2258"
          }}>{`#2258`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`LoggingClient.builder()
             .requestLogLevel(log -> ...)
             .responseLogLevel(log -> {
                 if (log.responseCause() == null ||
                     log.responseCause() instanceof HarmlessException) {
                     return LogLevel.INFO;
                 } else {
                     return LogLevel.WARN;
                 }
             });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ClientFactoryOptions`}</inlineCode>{` has been added to allow programmatic access to the `}<inlineCode parentName="p">{`ClientFactory`}</inlineCode>{` settings. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2230"
          }}>{`#2230`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory factory = ...;
boolean pipelining = factory.options().useHttp1Pipelining();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now disable Armeria's Netty-based asynchronous DNS resolver by specifying `}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.useJdkDnsResolver=true`}</inlineCode>{` JVM option. Use it only when the default resolver does not work. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2261"
          }}>{`#2261`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`💪 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Armeria client does not block a request anymore when DNS resolution takes long time. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2017"
        }}>{`#2017`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2217"
        }}>{`#2217`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2231"
        }}>{`#2231`}</a></li>
      <li parentName="ul">{`You do not have to prepend `}<inlineCode parentName="li">{`none+`}</inlineCode>{` prefix to non-RPC URIs anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2219"
        }}>{`#2219`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2241"
        }}>{`#2241`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
Clients.of("none+https://...", ...);
// After:
Clients.of("https://...", ...);
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`The behavior of `}<inlineCode parentName="li">{`Scheme.parse()`}</inlineCode>{` and `}<inlineCode parentName="li">{`tryParse()`}</inlineCode>{` has been improved in the same manner:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// Scheme.parse() now uses SerializationFormat.NONE automatically:
assert Scheme.parse("http") == Scheme.parse("none+http");
`}</code></pre>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria Spring auto-configuration now works correctly even when only `}<inlineCode parentName="li">{`GrpcServiceRegistrationBean`}</inlineCode>{`s are given. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2234"
        }}>{`#2234`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContext`}</inlineCode>{` is not pushed more often than necessary anymore when notifying `}<inlineCode parentName="li">{`RequestLogListener`}</inlineCode>{`s. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2227"
        }}>{`#2227`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`DynamicEndpointGroup`}</inlineCode>{` now handles the case where an endpoint address does not change but only a weight. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2240"
        }}>{`#2240`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HttpStatusException`}</inlineCode>{` now respects `}<inlineCode parentName="li">{`Flags.verboseExceptionSampler()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2253"
        }}>{`#2253`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`AccessLogWriter`}</inlineCode>{` does not fail with a `}<inlineCode parentName="li">{`ClassCastException`}</inlineCode>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2259"
        }}>{`#2259`}</a></li>
      <li parentName="ul">{`Fixed a bug where a service receives an `}<inlineCode parentName="li">{`OPTIONS`}</inlineCode>{` request even if the service did not opt-in for `}<inlineCode parentName="li">{`OPTIONS`}</inlineCode>{` method. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2263"
        }}>{`#2263`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ClientFactory.DEFAULT`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`ClientFactory.ofDefault()`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The getters for host-level settings in `}<inlineCode parentName="li">{`ServerConfig`}</inlineCode>{` have been deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2244"
        }}>{`#2244`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2246"
        }}>{`#2246`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
HttpService service = (ctx, req) -> {
    return HttpResponse.of("maxRequestLength: %d",
                           ctx.server().maxRequestLength());
};
// After:
HttpService service = (ctx, req) -> {
    return HttpResponse.of("maxRequestLength: %d",
                           ctx.virtualHost().maxRequestLength());
};
`}</code></pre>
      </li>
      <li parentName="ul">{`Continuing the migration to static `}<inlineCode parentName="li">{`builder()`}</inlineCode>{` factory methods, the following classes have switched from constructors to static `}<inlineCode parentName="li">{`builder()`}</inlineCode>{` methods: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2221"
        }}>{`#2221`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`ClientCacheControl`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`ClientConnectionTimings`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`ClientDecoration`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`ClientFactory`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`ClientOptions`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`ClientRequestContext`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`EndpointInfo`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`FieldInfo`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`HttpClient`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`LoggingClient`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`LoggingService`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`RequestContextCurrentTraceContext`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`RetrofitMeterIdPrefixFunction`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`ServerCacheControl`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`ServiceRequestContext`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The signatures of `}<inlineCode parentName="p">{`RequestContext.newDerivedContext()`}</inlineCode>{` have been changed so they always require `}<inlineCode parentName="p">{`RequestId`}</inlineCode>{`, `}<inlineCode parentName="p">{`HttpRequest`}</inlineCode>{` and `}<inlineCode parentName="p">{`RpcRequest`}</inlineCode>{` for less ambiguity. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2209"
          }}>{`#2209`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2224"
          }}>{`#2224`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Scheme.parse()`}</inlineCode>{` and `}<inlineCode parentName="p">{`tryParse()`}</inlineCode>{` do not fail anymore even if the scheme does not start with `}<inlineCode parentName="p">{`none+`}</inlineCode>{`: `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2219"
          }}>{`#2219`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2241"
          }}>{`#2241`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Scheme.parse() now uses SerializationFormat.NONE automatically:
assert Scheme.parse("http") == Scheme.parse("none+http");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ClientBuilderParams.uri()`}</inlineCode>{` does not return a URI with `}<inlineCode parentName="p">{`none+`}</inlineCode>{` prefix anymore. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2219"
          }}>{`#2219`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2241"
          }}>{`#2241`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`HttpClient`}</inlineCode>{` interface has been renamed to `}<inlineCode parentName="p">{`WebClient`}</inlineCode>{`. `}<inlineCode parentName="p">{`HttpClient`}</inlineCode>{` is now a different interface that extends `}<inlineCode parentName="p">{`Client<HttpRequest, HttpResponse>`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2254"
          }}>{`#2254`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Doesn't work:
HttpClient client = HttpClient.of("https://www.google.com/");
// Good:
WebClient client = WebClient.of("https://www.google.com/");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Services must implement `}<inlineCode parentName="p">{`HttpService`}</inlineCode>{` or `}<inlineCode parentName="p">{`RpcService`}</inlineCode>{` and clients must implement `}<inlineCode parentName="p">{`HttpClient`}</inlineCode>{` or `}<inlineCode parentName="p">{`RpcClient`}</inlineCode>{`: `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2239"
          }}>{`#2239`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2254"
          }}>{`#2254`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
public class FooHttpService implements Service<HttpRequest, HttpResponse> { ... }
public class FooRpcService implements Service<RpcRequest, RpcResponse> { ... }
public class FooHttpClient implements Client<HttpRequest, HttpResponse> { ... }
public class FooRpcClient implements Client<RpcRequest, RpcResponse> { ... }
// After:
public class FooHttpService implements HttpService { ... }
public class FooRpcService implements RpcService { ... }
public class FooHttpClient implements HttpClient { ... }
public class FooRpcClient implements RpcClient { ... }
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`You'll have to change any `}<inlineCode parentName="p">{`Service<...>`}</inlineCode>{` and `}<inlineCode parentName="p">{`Client<...>`}</inlineCode>{` usages in your code into `}<inlineCode parentName="p">{`HttpService`}</inlineCode>{`, `}<inlineCode parentName="p">{`RpcService`}</inlineCode>{`, `}<inlineCode parentName="p">{`HttpClient`}</inlineCode>{` or `}<inlineCode parentName="p">{`RpcClient`}</inlineCode>{`, unless you intended to express `}<em parentName="p">{`both`}</em>{` HTTP- and RPC- level types.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// Before:
Service<HttpRequest, HttpResponse> foo(Service<HttpRequest, HttpResponse> bar) { ... }
// After:
HttpService foo(HttpService bar) { ... }
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Similarly, you must use `}<inlineCode parentName="p">{`SimpleDecoratingHttpClient`}</inlineCode>{` or `}<inlineCode parentName="p">{`SimpleDecoratingRpcClient`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`SimpleDecoratingClient`}</inlineCode>{`, and `}<inlineCode parentName="p">{`SimpleDecoratingHttpService`}</inlineCode>{` or `}<inlineCode parentName="p">{`SimpleDecoratingRpcService`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`SimpleDecoratingService`}</inlineCode>{`.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// Does not work:
class MyDecoratorService extends SimpleDecoratingService<HttpRequest, HttpResponse> {
    MyDecoratorService(Service<HttpRequest, HttpResponse> delegate) {
        super(delegate);
    }
    ...
}
// Good:
class MyDecoratorService extends SimpleDecoratingHttpService {
    // Note the constructor parameter change.
    MyDecoratorService(HttpService delegate) {
        super(delegate);
    }
    ...
}
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`If you implemented your decorator without extending the `}<inlineCode parentName="p">{`SimpleDecorating{Http,Rpc}Service`}</inlineCode>{` or `}<inlineCode parentName="p">{`SimpleDecorating{Http,Rpc}Client`}</inlineCode>{`, then you must make sure that it implements `}<inlineCode parentName="p">{`HttpService`}</inlineCode>{`, `}<inlineCode parentName="p">{`RpcRequest`}</inlineCode>{`, `}<inlineCode parentName="p">{`HttpClient`}</inlineCode>{` or `}<inlineCode parentName="p">{`RpcClient`}</inlineCode>{`.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// Does not work:
class MyDecoratorService implements Service<HttpRequest, HttpResponse> {

    final Service<HttpRequest, HttpResponse> delegate;

    MyDecoratorService(Service<HttpRequest, HttpResponse> delegate) {
        this.delegate = delegate;
    }
    ...
}
// Good:
class MyDecoratorService implements HttpService {
    // Note the type change.
    final HttpService delegate;
    // Note the constructor parameter change.
    MyDecoratorService(HttpService delegate) {
        this.delegate = delegate;
    }
    ...
}
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Service.decorate()`}</inlineCode>{` has been pushed down to `}<inlineCode parentName="p">{`HttpService`}</inlineCode>{` and `}<inlineCode parentName="p">{`RpcService`}</inlineCode>{`: `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2239"
          }}>{`#2239`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2254"
          }}>{`#2254`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Does not work:
Service<HttpRequest, HttpResponse> service = (ctx, req) -> ...;
service.decorate(myDecorator); // No such method
// Good:
HttpService service (ctx, req) -> ...;
service.decorate(myDecorator); // OK!
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The type parameters of decorator `}<inlineCode parentName="p">{`Function`}</inlineCode>{`s have been changed. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2239"
          }}>{`#2239`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2254"
          }}>{`#2254`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
Function<Service<HttpRequest, HttpResponse>,
         ? extends Service<HttpRequest, HttpResponse>> httpServiceDecorator;
Function<Service<RpcRequest, RpcResponse>,
         ? extends Service<RpcRequest, RpcResponse>> rpcServiceDecorator;
Function<Client<HttpRequest, HttpResponse>,
         ? extends Client<HttpRequest, HttpResponse>> httpClientDecorator;
Function<Client<RpcRequest, RpcResponse>,
         ? extends Client<RpcRequest, RpcResponse>> rpcClientDecorator;
// After:
Function<? super HttpService, ? extends HttpService> httpServiceDecorator;
Function<? super RpcService, ? extends RpcService> rpcServiceDecorator;
Function<? super HttpClient, ? extends HttpClient> httpClientDecorator;
Function<? super RpcClient, ? extends RpcClient> rpcClientDecorator;
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Some types, which could be used at both HTTP- and RPC- level, have been split into two different types. For example:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`DecoratingClientFunction`}</inlineCode>{` has been split into `}<inlineCode parentName="li">{`DecoratingHttpClientFunction`}</inlineCode>{` and `}<inlineCode parentName="li">{`DecoratingRpcClientFunction`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`DecoratingServiceFunction`}</inlineCode>{` has been split into `}<inlineCode parentName="li">{`DecoratingHttpServiceFunction`}</inlineCode>{` and `}<inlineCode parentName="li">{`DecoratingRpcServiceFunction`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`LoggingClient`}</inlineCode>{` has been split into `}<inlineCode parentName="li">{`LoggingClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`LoggingRpcClient`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ServiceWithRoutes`}</inlineCode>{` has been split into `}<inlineCode parentName="li">{`HttpServiceWithRoutes`}</inlineCode>{` and `}<inlineCode parentName="li">{`RpcServiceWithRoutes`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`TransientService`}</inlineCode>{` has been split into `}<inlineCode parentName="li">{`TransientHttpService`}</inlineCode>{` and `}<inlineCode parentName="li">{`TransientRpcService`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The following services are now provided only at HTTP-level. Please let us know if you need an RPC-level version of them.`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`LoggingService`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`MetricCollectingService`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`StructuredLoggingService`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`KafkaStructuredLoggingService`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.8.0 -> 5.9.0`}</li>
      <li parentName="ul">{`gRPC 1.24.1 -> 1.25.0`}</li>
      <li parentName="ul">{`Jackson 2.10.0 -> 2.10.1`}</li>
      <li parentName="ul">{`Micrometer 1.3.0 -> 1.3.1`}</li>
      <li parentName="ul">{`Netty TCNative BoringSSL 2.0.26 -> 2.0.27`}</li>
      <li parentName="ul">{`Protobuf 3.9.1 -> 3.10.0`}</li>
      <li parentName="ul">{`RxJava 2.2.13 -> 2.2.14`}</li>
      <li parentName="ul">{`SLF4J 1.7.28 -> 1.7.29`}</li>
      <li parentName="ul">{`Spring Boot 2.1.9 -> 2.1.10`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'delegacy', 'eunchan-kim', 'heowc', 'hirakida', 'ikhoon', 'imasahiro', 'jrhee17', 'jyblue', 'matsumana', 'mauhiz', 'minwoox', 'rmohta', 'sivaalli', 'snaiper80', 'Stupremee', 'trustin', 'yanghun0070']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      